import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import Qm from "../layouts/QM";
import {featuresFr} from '../data/qm/features';

const QmFr = () => {
  const data = useStaticQuery(graphql`
    query qmDatasFr {
      image: file(relativePath: { eq: "qm/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulAvantagesGfa(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            functionalities {
              functionalities
            }
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulInstallationsGfa {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/queue-management'
    slider={true}
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Ecran tactile et application Attractif"
    title="Une gestion d'accueil intelligente" 
    subTitle="Attractif est le partenaire exclusif au Canada d'une solution de gestion d'accueil mondialement reconnue pour sa facilité d'utilisation et sa robustesse en matière de sécurité." 
    anchorLink="#qm-features-section">
      <Seo 
        title="Gestion de l'accueil et file d'attente"
        description="Attractif est le partenaire exclusif au Canada d'une solution de gestion d'accueil mondialement reconnue pour sa facilité d'utilisation et sa robustesse en matière de sécurité."
       />
      <Qm lang="fr-CA" features={featuresFr} types={data.allContentfulAvantagesGfa} carouselDatas={data.allContentfulInstallationsGfa.edges[0].node}/>
    </Layout>
  )
}

export default QmFr
